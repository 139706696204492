<template>
    <div class="uv-page">
        
        <v-container fluid>
            <v-row>
                <v-col></v-col>
                <v-col cols="4">
                    <v-card flat :loading="loading">
                        <v-card-title><span class="mx-auto">Please log in</span></v-card-title>
                        <v-form @keypress.enter.native="login">
                            <v-card-text>
                                <uv-text-field
                                    label="E-mail"
                                    v-model="email"
                                    rules="required|email"
                                ></uv-text-field>
                                <uv-text-field
                                    type="password"
                                    label="Password"
                                    v-model="password"
                                    rules="required"
                                >
                                    <template #append>
                                        <v-fade-transition leave-absolute>
                                            <uv-button small icon color="info" title="Am uitat parola" @click.stop="openResetPwdForm">
                                                <v-icon>mdi-help</v-icon>
                                            </uv-button>
                                        </v-fade-transition>
                                    </template>
                                </uv-text-field>
                            </v-card-text>
                        </v-form>
                        <v-card-actions>
                            <v-btn color="primary" raised :loading="loading" elevation="5" width="100%" tile @click="login" >
                                Login
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-container>
        <v-dialog
            :value="dialog"
            persistent
            max-width="30%">
            <v-card>
                <validation-observer v-slot="{ handleSubmit, invalid }">
                    <v-card-title>Reset password</v-card-title>
                    <v-card-subtitle class="grey--text ms-4">We will send the new password at the provided email address </v-card-subtitle>
                    <v-card-text>
                        <v-container fluid class="flex flex-column">
                            <v-row>
                                <v-col>
                                    <uv-text-field
                                        v-model="resetFormEmail"
                                        label="Email"
                                        rules="required|email">

                                    </uv-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="grey darken-1"
                            text
                            @click="closeResetPwdForm">
                            Discard
                        </v-btn>

                        <v-btn
                            color="green darken-1"
                            text
                            :loading="dialogIsLoading"
                            :disabled="invalid"
                            @click="handleSubmit(resetPwd)">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </validation-observer>
            </v-card>
        </v-dialog>
    
    
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, email} from "vuelidate/lib/validators";

export default {
    mixins:[validationMixin],
    validations:{
        email:{required, email},
        password:{required}
    },
    mounted: function() {
    },
    data: function(){
        return {
            email: "",
            password: "",
            loading: false,
            dialog: false,
            dialogIsLoading: false,
            resetFormEmail: ""
        }
    },
    computed: {
        emailErrors: function(){
            let errors = [];
            if (!this.$v.email.$dirty){
                return errors;
            }
            !this.$v.email.required && errors.push(this.$store.state.validationErrors.required);
            !this.$v.email.email && errors.push(this.$store.state.validationErrors.email);
            return errors;
        },
        pwdErrors: function(){
            let errors = [];
            if (!this.$v.password.$dirty){
                return errors;
            }
            !this.$v.password.required && errors.push(this.$store.state.validationErrors.required);
            return errors;
        }
    },
    methods: {
        login: function(){
            this.$v.$touch();
            if (this.$v.$error){
                return false;
            }
            
            this.loading = true;
            let bodyData = new FormData();
            bodyData.append("username", this.email);
            bodyData.append("password", this.password);
            bodyData.append("grant_type", "password");
            this.$apiHttp({
                method: "post",
                url: "/auth",
                data: bodyData
            })
            .then((res)=>{
                this.$store.commit("SET_apiToken", res.data);
                this.$router.push({ name: "Dashboard"});
            })
            .finally(()=>this.loading = false);
        },
        closeResetPwdForm() {
            this.resetFormEmail = "";
            this.dialog = false;
        },
        openResetPwdForm() {
            this.resetFormEmail = "";
            this.dialog = true;
        },
        resetPwd() {
            this.dialogIsLoading = true;
            let email = this.resetFormEmail;
            let vm = this;
            this.$apiHttp({
                method: "post",
                url: "/auth/reset_password",
                data: {
                    username: email
                }
            })
            .then((response) => {
                if(response.code == 200) {
                    vm.closeResetPwdForm();
                }
            }).finally(() => {
                    vm.dialogIsLoading = false;
                });
        }
    }
}
</script>